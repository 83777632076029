.App {
  font-family: sans-serif;
  text-align: center;
}

.images-container {
  display: flex;
  flex-wrap: wrap;
}

.image-card {
  margin: 10px;
}

.image-card img,
.image-card canvas {
  max-width: 400px;
  max-height: 400px;
}


.diagnose {
  position: relative;
  width: 182px;
  height: 531px;
}

.diagnose-bg,
.diagnose-leds {
  position: absolute;
  top: 0;
  left: 0;
  width: 182px;
  height: 531px;
}


.diagnose-led {
  position: absolute;
}

.diagnose-led.field {
  top: 107px;
  left: 73px;
  width: 87px;
  height: 13px;
}

.diagnose-led.status5 {
  top: 188px;
  left: 78px;
  width: 13px;
  height: 13px;
}
.diagnose-led.status6 {
  top: 188px;
  left: 100px;
  width: 13px;
  height: 13px;
}
.diagnose-led.status7 {
  top: 188px;
  left: 122px;
  width: 13px;
  height: 13px;
}
.diagnose-led.status8 {
  top: 188px;
  left: 144px;
  width: 13px;
  height: 13px;
}

.diagnose-led.status1 {
  top: 336px;
  left: 78px;
  width: 13px;
  height: 13px;
}
.diagnose-led.status2 {
  top: 336px;
  left: 100px;
  width: 13px;
  height: 13px;
}
.diagnose-led.status3 {
  top: 336px;
  left: 122px;
  width: 13px;
  height: 13px;
}
.diagnose-led.status4 {
  top: 336px;
  left: 144px;
  width: 13px;
  height: 13px;
}

.diagnose-led.ossd {
  top: 456px;
  left: 73px;
  width: 87px;
  height: 13px;
}


.diagnose-led.red {
  background: #f00;
}

.diagnose-led.green {
  background: #0f0;
}

.diagnose-led.blue {
  background: #00f;
}

.diagnose-led.yellow {
  background: #ff0;
}